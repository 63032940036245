
const protocol = window.location.protocol;
const host = window.location.host;
// const isProduction = host === "home.shsaman.cn" || host === "home.shsaman.cn" || host === "home.shsaman.cn";
const isProduction = host === "www.ehealthnow.net";
const isStaging = host === "testwww.ehealthnow.net" || host === "test.ehealthnow.net";
const isLocalDevelop = host === "localhost:3000" || host === "127.0.0.1:3000";
// const prefix = {domain :`${protocol}//api.ehealthnow.net/`, path: ''}
// const prefix = {domain :`${protocol}//47.103.82.11:31010/`, path: ''}
// const prefix = {domain : `${protocol}//localhost:8082/`, path: ''}

const prefix = {
    // domain: isProduction ? `${protocol}//101.132.38.221:8001` : (isStaging ? `${protocol}//101.132.38.221:8001` : `${protocol}//101.132.38.221:8001`),//修改最后一个地址有效
    domain: isProduction ? `${protocol}//api.ehealthnow.net/` : (isStaging ? `${protocol}//testapi.ehealthnow.net/` : `${protocol}//testapi.ehealthnow.net/`),
    // domain: isProduction ? `${protocol}//api.ehealthnow.net/` : (isStaging ? `${protocol}//testapi.ehealthnow.net/` : `${protocol}//localhost:8082/`),
    // path: '/web/'
    path: ''
}
export default prefix;


// http://81.68.202.169:9500/